import { Field } from '@api-platform/api-doc-parser';
import {
    ArrayInput,
    CheckboxGroupInput,
    maxLength,
    minValue,
    NullableBooleanInput,
    required,
    SimpleFormIterator,
    TextInput,
    Validator,
    isRequired,
} from 'react-admin';

import InputGuesser from '@components/form/InputGuesser';
import FileInputPreview from '@components/input/FileInputPreview';
import InputCopyableWrapper from '@components/input/InputCopyableWrapper';
import AutoCompleteGuesserInput from '@components/input/AutoCompleteGuesserInput';
import VideoImageInput from '@components/input/VideoImageInput';

import ProcessingTimeInput from '../../errand/input/ProcessingTimeInput';
import { StatusInputWithNotification } from '../../errand/input/StatusInput';

import PrintLabelInput from './PrintLabelInput';
import GoodsTypeInput from './GoodsTypeInput';
import ManufacturerInput from './ManufacturerInput';
import ModelInput from './ModelInput';
import SerialNumberInput from './SerialNumberInput';
import ResellersInput from './ResellersInput';
import OutgoingPriceInput from './OutgoingPriceInput';
import WeightInput from './WeightInput';
import CategoryDialogInput from './CategoryDialogInput';
import EanInput from './EanInput';
import TitleInput from './TitleInput';

import useInputAutoValidator from '@js/hooks/useInputAutoValidator';
import { ComponentChoices, Reclamation } from '@js/interfaces/reclamation';
import useInputInfoHelperText from '@js/hooks/useInputInfoHelperText';

import useWarningInputStyle from './useWarningInputStyle';
import useIsCopiedFieldFromSavingsCalculation from './useIsCopiedFieldFromSavingsCalculation';

interface Props {
    field: Field;
    source: string;
    fullWidth?: boolean;
    resource?: string;
    record?: Reclamation;
}

const ReclamationInputGuesser = ({ field, ...rest }: Props) => {
    const validate = useValidate(rest);
    const helperText = useInputInfoHelperText(rest);
    const warningClassName = useWarningInputStyle();
    const isFieldValueCopiedFromCo2 = useIsCopiedFieldFromSavingsCalculation(rest);

    const props = {
        ...rest,
        validate,
        helperText,
    };

    switch (field.name) {
        case 'powerAdapter':
            return <NullableBooleanInput {...props} nullLabel="app.label.n/a" style={{ minWidth: '220px' }} />;
        case 'category':
            return <CategoryDialogInput {...props} materialSource="material" />;
        case 'title':
            return <TitleInput {...props} />;
        case 'processingTimes':
            return <ProcessingTimeInput {...props} />;
        case 'status':
            return <StatusInputWithNotification {...props} />;
        case 'goodsType':
            return <GoodsTypeInput {...props} />;
        case 'manufacturer':
            return <ManufacturerInput {...props} />;
        case 'model':
            return <ModelInput {...props} />;
        case 'serialNumber':
            return <SerialNumberInput {...props} />;
        case 'resellers':
            return <ResellersInput {...props} />;
        case 'ean':
            return <EanInput {...props} />;
        case 'images':
            return (
                <VideoImageInput {...props} multiple accept="image/*, application/pdf, video/*">
                    <FileInputPreview reference="reclamation_media_objects" />
                </VideoImageInput>
            );
        case 'classificationComment':
            return <InputCopyableWrapper source={props.source} input={<InputGuesser {...props} fullWidth />} />;
        case 'outgoingPrice':
            return <OutgoingPriceInput {...props} />;
        case 'classificationOutComment':
            return <InputCopyableWrapper source={props.source} input={<InputGuesser {...props} fullWidth />} />;

        case 'links':
            return (
                <ArrayInput {...props}>
                    <SimpleFormIterator disableReordering TransitionProps={{ timeout: 0 }}>
                        <TextInput label="URL" source="link" validate={[required(), maxLength(254)]} fullWidth />
                    </SimpleFormIterator>
                </ArrayInput>
            );
        case 'printLabel':
            return <PrintLabelInput {...props} />;
        case 'containsComponents':
            return <CheckboxGroupInput {...props} choices={ComponentChoices} />;
        case 'size':
            return <AutoCompleteGuesserInput {...props} allowCreate />;
        case 'weight':
            return (
                <WeightInput
                    {...props}
                    fieldValueCopiedFromCo2={isFieldValueCopiedFromCo2}
                    warningClassName={warningClassName}
                />
            );
        default:
            return (
                <InputGuesser
                    field={field}
                    {...props}
                    {...(isFieldValueCopiedFromCo2 && {
                        className: warningClassName,
                        helperText: 'app.form.helperText.copied_field_from_savings_calculation',
                    })}
                />
            );
    }
};

const useValidate = (props: { source?: string; resource?: string; validate?: Validator | Validator[] }) => {
    const validate = useInputAutoValidator(props) ?? [];

    if (props.source === 'quantity') {
        validate.push(minValue(0));
    }
    if (props.source === 'category' && !isRequired(validate)) {
        validate.push(required());
    }

    return validate.length > 0 ? validate : undefined;
};

export default ReclamationInputGuesser;
