/* eslint-disable react/jsx-key */
import { Resource } from 'react-admin';
import { HydraAdmin } from '@api-platform/admin';

import Layout from '@js/components/Layout';
import Dashboard from '@js/components/Dashboard';
import ResourceGuesser from '@components/ResourceGuesser';
import LoginPage from '@components/routes/LoginPage';

import { AppConfigContextProvider } from '@js/context/AppConfigContext';
import { CustomThemeContext, CustomThemeContextProvider } from '@js/context/CustomThemeContext';

import getDataProvider from './hydra/dataProvider';
import authProvider from './auth/authProvider';
import { i18nProvider } from './locale/i18nProvider';
import customRoutes from './components/routes';
import lockRecordsReducer from '@js/redux/lockRecordsReducer';

import resources from '@js/components/resource';

const { entrypoint, mercureUrl } = window.ADMIN_APP;
const dataProvider = getDataProvider(entrypoint, mercureUrl);

const Admin = () => {
    return (
        <AppConfigContextProvider>
            <CustomThemeContextProvider>
                <CustomThemeContext.Consumer>
                    {({ theme }) => (
                        <HydraAdmin
                            dataProvider={dataProvider}
                            authProvider={authProvider}
                            entrypoint={entrypoint}
                            i18nProvider={i18nProvider}
                            customRoutes={customRoutes}
                            layout={Layout}
                            dashboard={Dashboard}
                            theme={theme ?? undefined}
                            customReducers={{ lockRecords: lockRecordsReducer }}
                            loginPage={LoginPage}
                            disableTelemetry
                        >
                            {(permissions) => [
                                // Not visible in menu. Has no views !
                                <Resource name="comments" />,
                                <Resource name="reclamation_media_objects" />,
                                <Resource name="user_group_logos" />,
                                <Resource name="errand_upload_images" />,
                                <Resource name="status_notifications" />,
                                <Resource name="processing_times" />,
                                <Resource name="auto_processing_times" />,
                                <Resource name="store_files" />,
                                <Resource name="sello_sales" />,
                                <Resource name="sello_order_rows" />,
                                <Resource name="sizes" />,
                                <Resource name="simple_products_groups" />,
                                <Resource name="category_savings" />,
                                <Resource name="errand_savings" />,
                                <Resource name="reclamation_savings" />,
                                <Resource name="category_economical_statistic_items" />,
                                <Resource name="manual_sales" />,
                                <Resource name="tags" />,
                                <Resource name="user_detailed_statistics" />,
                                <Resource name="errand_user_statistics" />,
                                <Resource name="reclamation_user_statistics" />,
                                <Resource name="purchase_separately_sales" />,
                                <Resource name="reclamation_statuses" />,
                                <Resource name="product_statuses" />,
                                // Register resource views
                                ...(Object.keys(resources) as (keyof typeof resources)[]).map((name) => (
                                    <ResourceGuesser permissions={permissions} name={name} {...resources[name]} />
                                )),
                            ]}
                        </HydraAdmin>
                    )}
                </CustomThemeContext.Consumer>
            </CustomThemeContextProvider>
        </AppConfigContextProvider>
    );
};

export default Admin;
