import statuses from './status';
import users from './user';
import goods_types from './goodsType';
import manufacturers from './manufacturer';
import models from './model';
import customers from './customer';
import addresses from './address';
import stores from './store';
import locations from './location';
import defects from './defect';
import functionalities from './functionality';
import states from './state';
import grades from './grade';
import depreciations from './depreciation';
import suppliers from './supplier';
import localization_labels from './localizationLabel';
import types from './type';
import errands from './errand';
import log_entries from './logEntry';
import countries from './country';
import workshops from './workshop';
import resellers from './resellers';
import errand_drafts from './errandDraft';
import api_tokens from './apiToken';
import contact_people from './contactPeople';
import user_groups from './user_groups';
import costs from './costs';
import user_notifications from './user_notifications';
import notification_events from './notification_events';
import predefined_texts from './predefined_texts';
import reclamations from './reclamation';
import settings from './settings';
import categories from './categories';
import products from './products';
import reports from './reports';
import external_sales from './external_sales';
import errands_exports from './errands_exports';

export default {
    statuses,
    users,
    goods_types,
    manufacturers,
    models,
    customers,
    addresses,
    stores,
    locations,
    defects,
    functionalities,
    states,
    grades,
    depreciations,
    suppliers,
    localization_labels,
    types,
    errands,
    log_entries,
    countries,
    workshops,
    resellers,
    errand_drafts,
    api_tokens,
    contact_people,
    user_groups,
    costs,
    user_notifications,
    notification_events,
    predefined_texts,
    reclamations,
    settings,
    categories,
    products,
    reports,
    external_sales,
    errands_exports,
};
